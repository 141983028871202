import axios from "axios"

import { ErrorResponse, PaginatedResponse } from "../../types"
import { Subscription } from "./types"



/*  ############################## */
/** ## SUBSCRIPTION API REQUESTS # */
/*  ############################## */
const useSubscriptions = () => {
  const listSubscriptions = (params?: Record<string, string>) => {
    return new Promise<PaginatedResponse<Subscription>>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscriptions/`,
        ...(params ? { params } : {})
      }).then((res) => {
        const resData = res.data as PaginatedResponse<Subscription>;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const getSubscription = (id: string) => {
    return new Promise<Subscription>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscriptions/${id}`
      }).then((res) => {
        const resData = res.data as Subscription;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  const cancelSubscription = (id: string) => {
    return new Promise<Subscription>((resolve, reject) => {
      axios({
        url: `${process.env.REACT_APP_SONA_AUTH_API_URL
          }/v2/subscriptions/${id}/cancel`,
        method: "delete"
      }).then((res) => {
        const resData = res.data as Subscription;
        resolve(resData);
      }).catch((error) => {
        reject(error as ErrorResponse);
      });
    });
  }

  return {
    listSubscriptions,
    getSubscription,
    cancelSubscription
  }
}

export default useSubscriptions;