import { PlanPriceInterval } from "../plans/types";



export const getVerboseInterval = (interval: PlanPriceInterval) => {
  switch (interval) {
    case "month":
      return "Monthly";
    case "year":
      return "Annually";
    default:
      return "Monthly";
  }
}
