import { Link } from "react-router-dom";
import moment from "moment";

import { currencySymbol, optionalString } from "../../../../../utils/strings";
import { TableColumn } from "../../../../../components/layout/Table/types";
import { accountPaths } from "../../../../../routes/accounts/paths";
import { defaultDateFormat } from "../../../../../utils/dates";
import { decimalPrice } from "../../../../../utils/numbers";
import {
  SubscriptionTxn
} from "../../../../../requests/subscriptionTxns/types";



export const useSubscriptionTxnUtils = () => {
  const tableColumns: TableColumn[] = [
    {
      title: "ECommPay Recurring ID",
      key: "recurringId",
      content: (txn: SubscriptionTxn) => (
        optionalString(txn.recurringId)
      )
    },
    {
      title: "Desired Plan",
      key: "desiredPlanPriceId",
      orderByKey: "desiredPlanPrice.plan.planType",
      content: (txn: SubscriptionTxn) => (
        txn.desiredPlanPrice.plan.name
      )
    },
    {
      title: "Amount",
      key: "transaction_amount",
      content: (txn: SubscriptionTxn) => (
        `${currencySymbol(txn.currency)
        }${decimalPrice(txn.amount)}`
      )
    },
    {
      title: "Status",
      key: "status",
      orderByKey: "status",
      content: (txn: SubscriptionTxn) => (
        <div className={`status ${txn.status}`}>
          {txn.status}
        </div>
      )
    },
    {
      title: "Type",
      key: "type",
      orderByKey: "type",
      content: (txn: SubscriptionTxn) => (
        <div className={`status ${txn.type} generic`}>
          {txn.type}
        </div>
      )
    },
    {
      title: "Starts On",
      key: "periodStart",
      orderByKey: "periodStart",
      content: (txn: SubscriptionTxn) => (
        moment(txn.periodStart).isValid()
          ? moment(txn.periodStart).format("Do MMM, YYYY @ hh:mm")
          : "-"
      )
    },
    {
      title: "Expires On",
      key: "periodEnd",
      orderByKey: "periodEnd",
      content: (txn: SubscriptionTxn) => (
        moment(txn.periodEnd).isValid()
          ? moment(txn.periodEnd).format("Do MMM, YYYY @ hh:mm")
          : "-"
      )
    },
    {
      title: "Purchased By",
      key: "purchasedById",
      orderByKey: "purchasedBy.username",
      content: (txn: SubscriptionTxn) => (
        <Link
          className="link underline"
          to={accountPaths.userDetail(txn.purchasedBy.username)}
        >
          {txn.purchasedBy.username}
        </Link>
      )
    },
    {
      title: "Created At",
      key: "createdAt",
      orderByKey: "createdAt",
      content: (txn: SubscriptionTxn) => (
        moment(txn.createdAt).isValid()
          ? moment(txn.createdAt).format("Do MMM, YYYY @ hh:mm")
          : "-"
      )
    },
    {
      title: "Updated At",
      key: "updatedAt",
      orderByKey: "updatedAt",
      content: (txn: SubscriptionTxn) => (
        moment(txn.updatedAt).isValid()
          ? moment(txn.updatedAt).format("Do MMM, YYYY @ hh:mm")
          : "-"
      )
    }
  ]

  return {
    tableColumns
  }
}