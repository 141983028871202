import styled from "styled-components";

import { colors } from "../../../../styles";



const Styles = styled.div`
  .transactions {
    margin-top: 2rem;
    .txn-filters {
      margin-bottom: 0.5rem;
    }
    .main-title {
      color: ${colors.grey2};
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
`;

export default Styles;