import { billingPaths } from "./paths";
import { RouteType } from "../types";
import {
  CreateEditDiscount,
  DiscountList,
  EditPlan,
  PlanDetail,
  PlanList,
  SubscriptionDetail,
  SubscriptionList
} from "../../pages/Billing/pages";



/*******************/
/** Billing Routes */
export const billingRoutes: RouteType[] = [
  // Plans
  {
    path: billingPaths.LIST_PLANS,
    element: <PlanList />
  },
  {
    path: billingPaths.PLAN_DETAIL,
    element: <PlanDetail />
  },
  {
    path: billingPaths.EDIT_PLAN,
    element: <EditPlan />
  },

  // Discounts
  {
    path: billingPaths.LIST_DISCOUNTS,
    element: <DiscountList />
  },
  {
    path: billingPaths.ADD_DISCOUNT,
    element: <CreateEditDiscount />
  },
  {
    path: billingPaths.EDIT_DISCOUNT,
    element: <CreateEditDiscount />
  },

  // Subscriptions
  {
    path: billingPaths.LIST_SUBSCRIPTIONS,
    element: <SubscriptionList />
  },
  {
    path: billingPaths.SUBSCRIPTION_DETAIL,
    element: <SubscriptionDetail />
  }
]