import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import { ModelDetailData } from "../../../../components/layout/ModelDetail";
import { Subscription } from "../../../../requests/subscriptions/types";
import useSubscriptions from "../../../../requests/subscriptions";
import { useSubscriptionDetailUtils } from "./utils";
import { URLParams } from "../../../../constants";
import {
  Loader, ModelDetail, PageHeader
} from "../../../../components/layout";
import SubscriptionTxns from "./Txns";
import Styles from "./styles";



function SubscriptionDetail() {
  const { subscriptionId } = useParams<URLParams>();
  const { getSubscription } = useSubscriptions();
  const [loading, setLoading] = useState<boolean>(true);
  const [subscription, setSubscription] = useState<Subscription>();
  const [ecommpayData, setEcommpayData] = useState<ModelDetailData[]>([]);
  // const [discountData, setDiscountData] = useState<ModelDetailData[][]>([]);
  const [subscriptionData, setSubscriptionData] = useState<
    ModelDetailData[]
  >([]);
  const {
    buildSubscriptionData, buildEcommpayData,
    confirmCancellation
  } = useSubscriptionDetailUtils();


  /**************************/
  /** Retrieve Subscription */
  const retrieveSubscription = async (id: string) => {
    try {
      setLoading(true);
      // Set Subscription
      const subscription = await getSubscription(id);
      setSubscription(subscription);
      setSubscriptionData(buildSubscriptionData(subscription));
      // setDiscountData(buildDiscountData(subscription.discounts));
      setEcommpayData(buildEcommpayData(subscription.recurringInfo));
    } catch { }
    finally {
      setLoading(false);
    }
  }



  useEffect(() => {
    if (!subscriptionId) return;
    retrieveSubscription(subscriptionId);
  }, [subscriptionId])




  return (
    <Styles className="root-content">
      {/* Header */}
      <PageHeader className="username-link" />

      {/* Main Content */}
      <div className="page-wrapper">
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="flex wrap">
              <ModelDetail
                title="Subscription"
                className="flex-fill"
                data={subscriptionData}
              />
              <div className="flex column flex-fill">
                {/* <div className="discounts flex-fill">
                {discountData.map(data =>
                  <ModelDetail
                    title="Discount"
                    className="flex-fill"
                    data={data}
                  />
                )}
              </div> */}

                <div className="ecommpay flex column flex-fill">
                  <ModelDetail
                    title="ECommPay"
                    data={ecommpayData}
                  />
                  <div className="flex justify-end align-center">
                    <button
                      className="button error auto-width"
                      onClick={(e) => confirmCancellation(e, subscription)}
                    >
                      Cancel Subscription
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Subscription Transactions */}
            <SubscriptionTxns subscription={subscription} />
          </>
        )}
      </div>
    </Styles>
  );
}

export default SubscriptionDetail;